/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ButtonTypes,
  FormButton,
  FormTextField,
  ICertMasterPracticeProduct,
  IError,
  IUpdateCertMasterPracticeProfile,
  LoadingOverlay,
  Vendors,
  IUpdateProfileRequest,
} from '@comptia-sso/core';
import ChevronRightRounded from '@material-ui/icons/ChevronRightRounded';
import { FormikProvider, useFormik } from 'formik';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components.
import { DetailsModal } from './DetailsModal';
import { InstructionModal } from './InstructionModal';
import { ProductsModal } from './ProductsModal';
import { SuccessModal } from './SuccessModal';

// Config.
import { env } from 'config';

// Hooks.
import {
  useGetCertMasterProducts,
  useProfile,
  useRedeemAccessKey,
  useRedeemCertMasterPracticeAccessKey,
  useTrackEvent,
  useUpdateCertMasterPracticeProfile,
  useTrackOdpEvent,
  useGetAccessKeyVendor,
  useUpdateProfilePopuli,
} from 'hooks';

// Styles.
import styles from 'views/HomeView/HomeView.module.scss';

// Utils.
import { needsAdditionalDetails } from 'utils';

// Validations.
import { getValidationSchema } from './validationSchema';
import { DemograpghicsModal } from './DemograpghicsModal';

export const RedeemAccessKeys = (): ReactElement => {
  const { t } = useTranslation();
  const [profile] = useProfile();
  const [updateCertMasterPracticeProfile] = useUpdateCertMasterPracticeProfile(
    profile?.id,
  );
  const [redeemAccessKey] = useRedeemAccessKey(profile?.id);
  const [getAccessKeyVendor] = useGetAccessKeyVendor();
  const [updateProfilePopuli] = useUpdateProfilePopuli(profile?.id);
  const [redeemCertMasterPracticeAccessKey] =
    useRedeemCertMasterPracticeAccessKey(profile?.id);
  const [getCertMasterProducts] = useGetCertMasterProducts(
    profile?.externalIds?.certMasterPractice,
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [instructionsOpen, setInstructionsOpen] = useState<boolean>(false);
  const [productList, setProductList] = useState<ICertMasterPracticeProduct[]>(
    [],
  );
  const [redeemedVendor, setRedeemedVendor] = useState<Vendors>();
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false);
  const [showDemographicsModal, setShowDemographicsModal] =
    useState<boolean>(false);
  const [showProductsModal, setShowProductsModal] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [redeemedProductCode, setRedeemedProductCode] = useState<string>();

  const trackEvent = useTrackEvent('Redeem Access Keys');
  const trackOdpEvent = useTrackOdpEvent();

  const formik = useFormik({
    initialValues: {
      accessKey: '',
    },
    onSubmit: async (values, { setFieldError }) => {
      try {
        trackEvent('Redeemed', { label: values.accessKey });
        await handleRedeemAccessKey(values.accessKey);
      } catch (error) {
        setFieldError(
          'accessKey',
          (error as IError)?.message || t('Toast.Error.Default'),
        );
      }
    },
    validateOnChange: false,
    validationSchema: getValidationSchema(t),
  });

  const handleRedeemAccessKey = async (accessKey: string) => {
    try {
      setIsSubmitting(true);

      // if populi vendor, ask for demographic data first.
      if ((await getAccessKeyVendor(accessKey)).vendor === Vendors.Populi) {
        setShowDemographicsModal(true);
        return;
      }

      const entitlement = await redeemAccessKey(accessKey);
      trackOdpEvent('form', 'Key Redemption', entitlement.product.title);
      // Show redeemed entitlement modal.
      setRedeemedVendor(entitlement.vendor);
      setRedeemedProductCode(entitlement.product.vendorProductCode);
      setShowSuccessModal(true);

      // Reset access key form.
      formik.resetForm();
    } catch (error) {
      if ((error as IError)?.statusCode === 404) {
        await checkForCertMasterProducts(accessKey);
      } else {
        formik.setFieldError(
          'accessKey',
          (error as IError)?.message || t('Toast.Error.Default'),
        );
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkForCertMasterProducts = async (accessKey: string) => {
    const products = await getCertMasterProducts(accessKey);
    setProductList(products);

    // Enter required details, select a product if more than 1, or redeem access key.
    if (needsAdditionalDetails(profile)) {
      setShowDetailsModal(true);
    } else if (products.length > 1) {
      setShowProductsModal(true);
    } else if (!products.length) {
      formik.setFieldError('accessKey', t('RedeemModule.NoProducts'));
    } else {
      await handleRedeemCertMasterPracticeAccessKey(
        accessKey,
        products[0].certMasterPracticeProductId,
        products[0].certMasterPracticeProductName,
      );
    }
  };

  const handleCertMasterDetailsSubmit = async (
    values: IUpdateCertMasterPracticeProfile,
  ) => {
    // Update profile with required details.
    await updateCertMasterPracticeProfile(values);
    setShowDetailsModal(false);

    // Select a product if more than 1 or redeem access key.
    if (productList.length > 1) {
      setShowProductsModal(true);
    } else if (!productList.length) {
      formik.setFieldError('accessKey', t('RedeemModule.NoProducts'));
    } else {
      await handleRedeemCertMasterPracticeAccessKey(
        formik.values.accessKey,
        productList[0].certMasterPracticeProductId,
        productList[0].certMasterPracticeProductName,
      );
    }
  };

  const handleCertMasterProductsSubmit = async (values: {
    productId: number;
    productName: string;
  }) => {
    setShowProductsModal(false);
    await handleRedeemCertMasterPracticeAccessKey(
      formik.values.accessKey,
      values.productId,
      values.productName,
    );
  };

  const handleRedeemCertMasterPracticeAccessKey = async (
    accessKey: string,
    certMasterProductId: number,
    certMasterProductName: string,
  ) => {
    try {
      setIsSubmitting(true);

      // Attempt to redeem CMP access key.
      await redeemCertMasterPracticeAccessKey({
        accessKey,
        certMasterProductId,
      });

      // Show redeemed entitlement modal.
      setRedeemedVendor(Vendors.CertMasterPractice);
      setShowSuccessModal(true);

      trackOdpEvent('form', 'Key Redemption', certMasterProductName);

      // Reset access key form.
      formik.resetForm();
    } catch (error) {
      formik.setFieldError(
        'accessKey',
        (error as IError)?.message || t('Toast.Error.Default'),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDemographicsSubmit = async (values: IUpdateProfileRequest) => {
    try {
      setIsSubmitting(true);
      // Update profile with required details.
      await updateProfilePopuli(values);

      const entitlement = await redeemAccessKey(formik.values.accessKey);
      trackOdpEvent('form', 'Key Redemption', entitlement.product.title);
      // Show redeemed entitlement modal.
      setRedeemedVendor(entitlement.vendor);
      setRedeemedProductCode(entitlement.product.vendorProductCode);
      setShowDemographicsModal(false);
      setShowSuccessModal(true);
      setIsSubmitting(false);

      // Reset access key form.
      formik.resetForm();
    } catch (error) {
      setShowDemographicsModal(false);
      setIsSubmitting(false);
      formik.setFieldError(
        'accessKey',
        (error as IError)?.message || t('Toast.Error.Default'),
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className={styles.card}>
      <div className={styles.content}>
        {env.FLAG_LEARNING_SYSTEMS_ENABLED ? (
          <>
            <form noValidate onSubmit={formik.handleSubmit}>
              <FormikProvider value={formik}>
                <h2>{t('RedeemModule.Title')}</h2>
                <p>{t('RedeemModule.Content')}</p>
                <FormTextField
                  className={styles['text-field']}
                  label={t('RedeemModule.Label')}
                  name="accessKey"
                />
                <a
                  className={styles.link}
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    trackEvent('Clicked', {
                      label: t('RedeemInformation.Title'),
                    });
                    setInstructionsOpen(true);
                  }}
                >
                  <span>{t('RedeemInformation.Title')}</span>
                  <ChevronRightRounded />
                </a>
                <FormButton
                  className={styles.button}
                  disabled={!formik.dirty}
                  type={ButtonTypes.Submit}
                >
                  {t('RedeemModule.Button')}
                </FormButton>
              </FormikProvider>
            </form>
            <InstructionModal
              onClose={() => setInstructionsOpen(false)}
              open={instructionsOpen}
            />
            <DemograpghicsModal
              onClose={() => setShowDemographicsModal(false)}
              open={showDemographicsModal}
              onSubmit={handleDemographicsSubmit}
              profile={profile}
            />
            <DetailsModal
              onClose={() => setShowDetailsModal(false)}
              open={showDetailsModal}
              onSubmit={handleCertMasterDetailsSubmit}
              profile={profile}
            />
            <ProductsModal
              onClose={() => setShowProductsModal(false)}
              open={showProductsModal}
              onSubmit={handleCertMasterProductsSubmit}
              products={productList}
            />
            <SuccessModal
              onClose={() => setShowSuccessModal(false)}
              open={showSuccessModal}
              vendor={redeemedVendor}
              productCode={redeemedProductCode}
            />
            <LoadingOverlay
              isOpen={isSubmitting}
              text={t('Loading.RedeemingAccessKey')}
            />
          </>
        ) : (
          <>
            <h2>{t('RedeemModule.Title')}</h2>
            <div>
              <p>
                <strong>{t('ComingSoon.ReleaseDate')}</strong>
              </p>
              <p>{t('ComingSoon.RedeemAccessKeys')}</p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
