import { ReactElement, useEffect } from 'react';

export const Clarity = (): ReactElement => {
  useEffect(() => {
    const script = document.createElement('script');

    script.async = true;
    script.innerHTML =
      '(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);})(window, document, "clarity", "script", "nf81bpfa3c");';

    document.head.appendChild(script);
  }, []);

  return <div />;
};

export default Clarity;
