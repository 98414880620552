import {
  Button,
  IError,
  LoadingOverlay,
  Spinner,
  Vendors,
  useToast,
} from '@comptia-sso/core';
import { MouseEvent, ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Config.
import { env } from 'config';

// Hooks.
import {
  useProfile,
  useProfileEntitlements,
  useRedirectToVendor,
  useTrackEvent,
  useTrackOdpEvent,
} from 'hooks';

// Icons.
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LaptopMacIcon from '@material-ui/icons/LaptopMac';

// Images.
import AccessYourTrainingImage from 'images/learning-products.jpg';

// Styles.
import styles from './AccessYourTraining.module.scss';

export const AccessYourTraining = (): ReactElement => {
  const { t } = useTranslation();
  const [popToast] = useToast();
  const [profile] = useProfile();
  const [
    entitlements,
    { error: entitlementsError, isLoading: isLoadingEntitlements },
  ] = useProfileEntitlements(profile?.id);
  const trackEvent = useTrackEvent('Access Your Training');
  const trackOdpEvent = useTrackOdpEvent();
  const [redirectToVendor, { isLoading: isRedirecting }] = useRedirectToVendor(
    profile?.id,
  );
  const hasSkillable = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.vendor?.toLowerCase() ===
            Vendors.Skillable.toLowerCase() &&
          new Date(entitlement?.expirationDate) > new Date(),
      ),
    [entitlements],
  );
  const hasBenchPrep = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.vendor?.toLowerCase() ===
            Vendors.BenchPrep.toLowerCase() &&
          entitlement.isCertmasterCE === false &&
          (new Date(entitlement?.expirationDate) > new Date() ||
            (entitlement.expirationDate == null &&
              entitlement.redemptionDate == null)),
      ),
    [entitlements],
  );
  const hasGutenberg = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.vendor?.toLowerCase() ===
            Vendors.Gutenberg.toLowerCase() &&
          new Date(entitlement?.expirationDate) > new Date(),
      ),
    [entitlements],
  );
  const hasPopuli = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.vendor?.toLowerCase() === Vendors.Populi.toLowerCase() &&
          new Date(entitlement?.expirationDate) > new Date(),
      ),
    [entitlements],
  );
  const hasAnyEnabledPopuli = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.vendor?.toLowerCase() === Vendors.Populi.toLowerCase() &&
          new Date(entitlement?.expirationDate) > new Date() &&
          entitlement?.enableDateTime &&
          new Date(entitlement?.enableDateTime) <= new Date(),
      ),
    [entitlements],
  );
  const hasCertMasterPractice = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.vendor?.toLowerCase() ===
            Vendors.CertMasterPractice.toLowerCase() &&
          new Date(entitlement?.expirationDate) > new Date(),
      ),
    [entitlements],
  );

  const hasCertMasterCE = useMemo(
    () =>
      entitlements.some(
        (entitlement) =>
          entitlement?.isCertmasterCE &&
          new Date(entitlement?.expirationDate) > new Date(),
      ),
    [entitlements],
  );

  const handleEvent = (e: MouseEvent<HTMLElement>) => {
    const element = e.currentTarget as HTMLElement;
    trackEvent('Clicked', { label: element.innerText });
    trackOdpEvent('navigation', 'Launchpad Training - Home', element.innerText);
  };

  const handleVendorClick = (vendor: Vendors) => {
    return async (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();
      handleEvent(e);

      try {
        await redirectToVendor(vendor);
      } catch (error) {
        popToast((error as IError)?.message || t('Toast.Error.Default'));
      }
    };
  };

  useEffect(() => {
    if (entitlementsError) {
      popToast(
        (entitlementsError as IError)?.message || t('Toast.Error.Default'),
      );
    }
  }, [entitlementsError]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.accessYourTraining}>
          <img src={AccessYourTrainingImage} alt="" />
          {env.FLAG_LEARNING_SYSTEMS_ENABLED ? (
            <div className={styles.accessYourTrainingDetails}>
              <div className={styles.accessYourTrainingTop}>
                <div>
                  <h2>{t('AccessYourTraining.Title')}</h2>
                  <p>{t('AccessYourTraining.Content')}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t('AccessYourTraining.Content2'),
                    }}
                  />
                </div>
                <div>
                  {!hasSkillable &&
                    !hasBenchPrep &&
                    !hasGutenberg &&
                    !hasCertMasterPractice &&
                    !hasCertMasterCE &&
                    !hasPopuli && (
                      <Button
                        className={styles.button}
                        link
                        onClick={handleEvent}
                        to="https://store.comptia.org"
                      >
                        {t('AccessYourTraining.Button')}
                      </Button>
                    )}
                </div>
              </div>
              <div className={styles.accessYourTrainingBottom}>
                {isLoadingEntitlements ? (
                  <Spinner className={styles.spinner} />
                ) : (
                  <ul className={styles.linksList}>
                    {hasSkillable && (
                      <li>
                        <a
                          href="#"
                          onClick={handleVendorClick(Vendors.Skillable)}
                        >
                          <LaptopMacIcon />
                          <span>
                            {t('AccessYourTraining.Link-7')},<br />
                            {t('AccessYourTraining.Link-1')}
                          </span>
                          <ArrowForwardIcon />
                        </a>
                      </li>
                    )}
                    {hasCertMasterPractice && (
                      <li>
                        <a
                          href="#"
                          onClick={handleVendorClick(
                            Vendors.CertMasterPractice,
                          )}
                        >
                          <LaptopMacIcon />
                          <span>{t('AccessYourTraining.Link-2')}</span>
                          <ArrowForwardIcon />
                        </a>
                      </li>
                    )}
                    {hasBenchPrep && (
                      <li>
                        <a
                          href="#"
                          onClick={handleVendorClick(Vendors.BenchPrep)}
                        >
                          <LaptopMacIcon />
                          <span>
                            {t('AccessYourTraining.Link-3')},<br />
                            {t('AccessYourTraining.Link-4')}
                          </span>
                          <ArrowForwardIcon />
                        </a>
                      </li>
                    )}
                    {hasPopuli && (
                      <li>
                        <a
                          href="#"
                          onClick={
                            hasAnyEnabledPopuli
                              ? handleVendorClick(Vendors.Canvas)
                              : (e) => {
                                  e.preventDefault();
                                }
                          }
                        >
                          <LaptopMacIcon />
                          <span>
                            {t('AccessYourTraining.Link-8')}
                            {!hasAnyEnabledPopuli && (
                              <span className={styles.waiting}>
                                ({t('TrainingProducts.WaitingOnAgreement')})
                              </span>
                            )}
                          </span>
                          <ArrowForwardIcon />
                        </a>
                      </li>
                    )}
                    {hasGutenberg && (
                      <li>
                        <a
                          href="#"
                          onClick={handleVendorClick(Vendors.Gutenberg)}
                        >
                          <LaptopMacIcon />
                          <span>{t('AccessYourTraining.Link-5')}</span>
                          <ArrowForwardIcon />
                        </a>
                      </li>
                    )}
                    {hasCertMasterCE && (
                      <li>
                        <a
                          href="#"
                          onClick={handleVendorClick(Vendors.BenchPrep)}
                        >
                          <LaptopMacIcon />
                          <span>{t('AccessYourTraining.Link-6')}</span>
                          <ArrowForwardIcon />
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </div>
          ) : (
            <div className={styles.accessYourTrainingComingSoon}>
              <h2>{t('AccessYourTraining.Title')}</h2>
              <Button
                className={styles['coming-soon__button']}
                link
                to="https://www.comptia.org/training"
              >
                {t('ComingSoon.BrowseTraining')}
              </Button>
              <div className={styles.accessYourTrainingComingSoonContent}>
                <p>
                  <strong>{t('ComingSoon.ReleaseDate')}</strong>
                </p>
                <p>{t('ComingSoon.AccessYourTraining')}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <LoadingOverlay isOpen={isRedirecting} text={t('Loading.Default')} />
    </>
  );
};
